<template>
  <div>
    <v-container>
      <page-title title="Department">
        <template slot="action">
          <v-btn color="primary" :to="{ name: 'HumanResource.Department.Add' }">Add</v-btn>
        </template>
      </page-title>
      <v-row>
        <v-col>
          <card-expansion title="List Department" no-padding>
            <list-data></list-data>
          </card-expansion>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ListData from './components/ListData.vue';
export default {
  components: { ListData },
};
</script>